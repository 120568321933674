
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getDocs, addDoc, updateDoc, deleteDoc as apiDeleteDoc } from '@/api/docs'
import type { DocData, DocItem } from '@/api/model/docsModel'
import { usePagination } from '@/hooks/usePagination'
import { dateFormat } from '@/utils/date'

const fields = [
  { label: '编号', prop: 'id', width: 80 },
  {
    label: '文档名称',
    prop: 'documentTitle',
    width: 400,
    showOverflowTooltip: true
  },
  {
    label: '文档链接',
    prop: 'documentUrl',
    showOverflowTooltip: true
  },
  {
    label: '创建时间',
    prop: 'createdAt',
    width: 150,
    formatter: row => dateFormat(row.createdAt)
  },
  { label: '创建者', prop: 'createName', width: 90 }
  // { label: '状态', prop: 'status', width: 80 },
  // { label: '操作', prop: 'action', width: 120 }
]

const rules = {
  documentTitle: [{ required: true, message: '请输入文档名称' }],
  documentUrl: [{ required: true, message: '请输入文档链接' }]
}

export default defineComponent({
  name: 'Docs',
  setup() {
    // 列表分页
    const rows = reactive<DocItem[]>([])
    const loadList = async (pageNo, pageSize) => {
      const params = {
        pageNo,
        pageSize
      }
      const { success, result } = await getDocs(params)
      if (success && result) {
        rows.splice(0, rows.length, ...result.data)
      }
      return {
        success,
        total: result?.total || 0
      }
    }
    const {
      pager,
      load: loadPageList,
      refresh,
      handleCurrentPageChange,
      handlePageSizeChange
    } = usePagination({ load: loadList })
    loadPageList()

    // 新增、编辑文档
    const docForm = ref()
    const dialogVisible = ref(false)
    const randomFormKey = ref(0)
    const handleCreateDoc = () => {
      delete formData.id
      formData.documentTitle = ''
      formData.documentUrl = ''
      randomFormKey.value = Date.now()
      dialogVisible.value = true
    }
    const handleEditDoc = (row: DocItem) => {
      formData.id = row.id
      formData.documentTitle = row.documentTitle
      formData.documentUrl = row.documentUrl
      dialogVisible.value = true
    }

    // 删除文档
    const handleDeleteDoc = (row: DocItem) => {
      ElMessageBox.confirm('确定要删除码？')
        .then(() => deleteDoc(row.id))
        .catch(err => console.log(err))
    }
    const deleteDoc = async (id: number) => {
      const res = await apiDeleteDoc(id, { loading: true })
      if (res.success) {
        ElMessage.success('操作成功~')
        refresh()
      }
    }

    // 状态切换
    const handleStatusChange = (enabled: boolean, id: number) => changeStatus(enabled, id)
    const changeStatus = async (enabled: boolean, id: number) => {
      const status = enabled ? 1 : 0
      const res = await updateDoc({ id, status }, { loading: true })
      if (res.success) {
        const row = rows.find(item => item.id === id)
        if (row) {
          row.status = status
        } else {
          refresh()
        }
      }
    }

    const formData = reactive<DocData>({
      documentTitle: '',
      documentUrl: ''
    })
    const handleSubmit = () => {
      docForm.value.validate(valid => valid && submit())
    }
    const submitting = ref(false)
    const submit = async () => {
      const data = { ...formData }
      data.documentTitle = data.documentTitle?.trim() || ''
      data.documentUrl = data.documentUrl?.trim() || ''
      const promise = formData.id ? updateDoc(data) : addDoc(data)
      submitting.value = true
      const res = await promise
      submitting.value = false
      if (res.success) {
        ElMessage.success('操作成功~')
        dialogVisible.value = false
        refresh()
      }
    }

    const handleViewErrorLink = () => ElMessage.error('链接错误')

    return {
      fields,
      rows,
      pager,
      handleCurrentPageChange,
      handlePageSizeChange,
      handleCreateDoc,
      handleEditDoc,
      handleDeleteDoc,
      handleStatusChange,
      dialogVisible,
      docForm,
      formData,
      randomFormKey,
      rules,
      submitting,
      handleSubmit,
      handleViewErrorLink
    }
  }
})
