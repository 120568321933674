import { get, post, put, _delete } from '@/utils/http/request'
import type { RequestOptions } from '@/utils/http/types'
import type { PagerParams, ListResult } from './types'
import type { DocData, DocItem } from './model/docsModel'

// 文档列表
export function getDocs(params?: PagerParams, options?: RequestOptions) {
  return get<ListResult<DocItem>>('sys/document/page', params, options)
}

// 新增文档
export function addDoc(data: DocData, options?: RequestOptions) {
  return post('sys/document', data, options)
}

// 编辑文档
export function updateDoc(data: DocData, options?: RequestOptions) {
  return put('sys/document', data, options)
}

// 删除文档
export function deleteDoc(id: number, options?: RequestOptions) {
  return _delete(`sys/document/${id}`, {}, options)
}
