/**
 * 分页
 */
import { reactive } from 'vue'
import { toNumber, Param } from '@/utils/url'

type LoadFunc = (page: number, pageSize?: number) => Promise<{ success: boolean, total: number }>

export interface PageOption {
  current?: number
  pageSize?: number
  load?: LoadFunc
}

export interface Pagination {
  current: number
  pageSize: number
  total: number
  loading: boolean
}

export function usePagination(option: PageOption = { current: 1, pageSize: 10 }) {
  const { current, pageSize } = option

  const load = async (current: Param = 1, pageSize?: Param) => {
    current = toNumber(current, 1)
    pageSize = toNumber(pageSize, pager.pageSize)
    if (option.load) {
      pager.loading = true
      const res = await option.load(current, pageSize)
      pager.loading = false
      if (res.success) {
        pager.current = current
        if (pageSize) {
          pager.pageSize = pageSize
        }
        pager.total = res.total
      }
    }
  }

  const pager = reactive<Pagination>({
    current: current || 1,
    pageSize: pageSize || 10,
    total: 0,
    loading: false
  })

  const refresh = () => load(pager.current, pager.pageSize)

  const handleCurrentPageChange = (page: number) => load(page)
  const handlePageSizeChange = (pageSize: number) => load(1, pageSize)

  return {
    pager,
    load,
    refresh,
    handleCurrentPageChange,
    handlePageSizeChange
  }
}
