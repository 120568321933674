/**
 * 路径相关
 */
import type { LocationQueryValue } from 'vue-router'

export function getFileNameOfUrl(url: string): string | void {
  const res = /[^/]+$/.exec(url)
  if (res) {
    return res[0]
  }
}

export type Param = LocationQueryValue | LocationQueryValue[] | number | undefined

// 参数转换
export function toNumber(param: Param, def = 1) {
  if (Array.isArray(param)) {
    param = param[0]
  }
  if (typeof param === 'number') {
    return param
  }
  if (typeof param === 'string') {
    return Number.parseInt(param) || def
  }
  return def
}
