<template>
  <AppPage>
    <SearchBar>
      <template #side>
        <el-button type="primary" @click="handleCreateDoc">新增文档</el-button>
      </template>
    </SearchBar>
    <div v-loading="pager.loading" class="list-container">
      <el-table border :data="rows">
        <el-table-column
          v-for="field in fields"
          :key="field.prop"
          :label="field.label"
          :prop="field.prop"
          :formatter="field.formatter"
          :width="field.width"
          :show-overflow-tooltip="field.showOverflowTooltip"
        />
        <el-table-column label="状态" prop="status" width="80">
          <template #="{ row }">
            <el-switch
              :model-value="row.status === 1"
              @change="handleStatusChange($event, row.id)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #="{ row }">
            <a
              v-if="row.documentUrl?.indexOf('http') === 0"
              :href="row.documentUrl"
              class="table-link-button"
              target="_blank"
            >预览</a>
            <a v-else class="table-link-button" @click="handleViewErrorLink">预览</a>
            <a class="table-link-button" @click.prevent="handleEditDoc(row)">编辑</a>
            <a class="table-link-button" @click.prevent="handleDeleteDoc(row)">删除</a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <app-pagination
      :current-page="pager.current"
      :page-size="pager.pageSize"
      :total="pager.total"
      @current-change="handleCurrentPageChange"
      @size-change="handlePageSizeChange"
    />
  </AppPage>
  <el-dialog
    v-model="dialogVisible"
    width="35em"
    :title="formData.id ? '编辑文档' : '新增文档'"
    :show-close="!submitting"
    :close-on-click-modal="!submitting"
    :close-on-press-escape="!submitting"
  >
    <el-form
      ref="docForm"
      id="doc_form"
      :key="formData.id || randomFormKey"
      :model="formData"
      :rules="rules"
      @submit.prevent="handleSubmit"
    >
      <el-form-item label="文档名称" prop="documentTitle">
        <el-input
          v-model="formData.documentTitle"
          placeholder="请输入文档名称"
          show-word-limit
          :maxlength="32"
        />
      </el-form-item>
      <el-form-item label="文档链接" prop="documentUrl">
        <el-input v-model="formData.documentUrl" placeholder="请输入文档链接" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button :disabled="submitting" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          native-type="submit"
          form="doc_form"
          :loading="submitting"
        >保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getDocs, addDoc, updateDoc, deleteDoc as apiDeleteDoc } from '@/api/docs'
import type { DocData, DocItem } from '@/api/model/docsModel'
import { usePagination } from '@/hooks/usePagination'
import { dateFormat } from '@/utils/date'

const fields = [
  { label: '编号', prop: 'id', width: 80 },
  {
    label: '文档名称',
    prop: 'documentTitle',
    width: 400,
    showOverflowTooltip: true
  },
  {
    label: '文档链接',
    prop: 'documentUrl',
    showOverflowTooltip: true
  },
  {
    label: '创建时间',
    prop: 'createdAt',
    width: 150,
    formatter: row => dateFormat(row.createdAt)
  },
  { label: '创建者', prop: 'createName', width: 90 }
  // { label: '状态', prop: 'status', width: 80 },
  // { label: '操作', prop: 'action', width: 120 }
]

const rules = {
  documentTitle: [{ required: true, message: '请输入文档名称' }],
  documentUrl: [{ required: true, message: '请输入文档链接' }]
}

export default defineComponent({
  name: 'Docs',
  setup() {
    // 列表分页
    const rows = reactive<DocItem[]>([])
    const loadList = async (pageNo, pageSize) => {
      const params = {
        pageNo,
        pageSize
      }
      const { success, result } = await getDocs(params)
      if (success && result) {
        rows.splice(0, rows.length, ...result.data)
      }
      return {
        success,
        total: result?.total || 0
      }
    }
    const {
      pager,
      load: loadPageList,
      refresh,
      handleCurrentPageChange,
      handlePageSizeChange
    } = usePagination({ load: loadList })
    loadPageList()

    // 新增、编辑文档
    const docForm = ref()
    const dialogVisible = ref(false)
    const randomFormKey = ref(0)
    const handleCreateDoc = () => {
      delete formData.id
      formData.documentTitle = ''
      formData.documentUrl = ''
      randomFormKey.value = Date.now()
      dialogVisible.value = true
    }
    const handleEditDoc = (row: DocItem) => {
      formData.id = row.id
      formData.documentTitle = row.documentTitle
      formData.documentUrl = row.documentUrl
      dialogVisible.value = true
    }

    // 删除文档
    const handleDeleteDoc = (row: DocItem) => {
      ElMessageBox.confirm('确定要删除码？')
        .then(() => deleteDoc(row.id))
        .catch(err => console.log(err))
    }
    const deleteDoc = async (id: number) => {
      const res = await apiDeleteDoc(id, { loading: true })
      if (res.success) {
        ElMessage.success('操作成功~')
        refresh()
      }
    }

    // 状态切换
    const handleStatusChange = (enabled: boolean, id: number) => changeStatus(enabled, id)
    const changeStatus = async (enabled: boolean, id: number) => {
      const status = enabled ? 1 : 0
      const res = await updateDoc({ id, status }, { loading: true })
      if (res.success) {
        const row = rows.find(item => item.id === id)
        if (row) {
          row.status = status
        } else {
          refresh()
        }
      }
    }

    const formData = reactive<DocData>({
      documentTitle: '',
      documentUrl: ''
    })
    const handleSubmit = () => {
      docForm.value.validate(valid => valid && submit())
    }
    const submitting = ref(false)
    const submit = async () => {
      const data = { ...formData }
      data.documentTitle = data.documentTitle?.trim() || ''
      data.documentUrl = data.documentUrl?.trim() || ''
      const promise = formData.id ? updateDoc(data) : addDoc(data)
      submitting.value = true
      const res = await promise
      submitting.value = false
      if (res.success) {
        ElMessage.success('操作成功~')
        dialogVisible.value = false
        refresh()
      }
    }

    const handleViewErrorLink = () => ElMessage.error('链接错误')

    return {
      fields,
      rows,
      pager,
      handleCurrentPageChange,
      handlePageSizeChange,
      handleCreateDoc,
      handleEditDoc,
      handleDeleteDoc,
      handleStatusChange,
      dialogVisible,
      docForm,
      formData,
      randomFormKey,
      rules,
      submitting,
      handleSubmit,
      handleViewErrorLink
    }
  }
})
</script>
